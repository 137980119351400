export const neutralColors = {
  neutral100: '#211D17',
  neutral70: '#64615D',
  neutral50: '#908E8B',
  neutral20: '#D3D2D1',
  neutral8: '#EDEDEC',
  white: '#FFFFFF',
};

export const primaryColors = {
  purple400: '#1F0D40',
  purple300: '#2D154F',
  purple200: '#3C1E5F',
  purple100: '#4D2A6F',
  purple80: '#71558C',
  purple60: '#907BA6',
  purple50: '#A694B7',
  purple40: '#B8AAC5',
  purple20: '#DBD4E2',
  purple15: '#E4DFE9',
  purple10: '#EDEAF1',
  purple5: '#F6F4F8',
};

export const accentColors = {
  rose100: '#E9C9C3',
  rose80: '#EDD4CF',
  rose50: '#F4E4E1',
  rose15: '#FCF7F6',
  indianSummer: '#D56048',
  indianSummerDark: '#AC4E39',
  forestGreen: '#DFE8E6',
  zippyYellow: '#FAFBCC',
};

export const statusColors = {
  success: '#439C8B',
  successWeak: '#D8EBC2',
  error: '#B74849',
  errorWeak: '#F1DADB',
  waiting: '#ECCD5F',
  waitingWeak: '#FFE0B2',
  neutral: '#B6AAC3',
  completed: '#EDEDEC',
  updated: '#D4EDFF',
};

export const chartColors = {
  empty: '#EDEDEC',
  green: '#8CC24C',
  greenAA: '#70A037',
  red: '#E26C6E',
  redAA: '#AB5354',
  yellow: '#ECCD5F',
  yellowAA: '#B29015',
  muted: '#E4DFE9',
};

export const textColors = {
  primary: '#4D2A6F',
  primaryWeak: '#71558C',
  onAccent: '#FFFFFF',
  link: '#6C42A0',
  neutralWeakest: '#D3D2D1',
  neutralWeak: '#908E8B',
  neutral: '#64615D',
  neutralStrong: '#211D17',
  error: '#B74849',
  errorStrong: '#211D17',
  success: '#70A037',
  successStrong: '#004734',
  waitingStrong: '#342105',
};

export const borderColors = {
  weak: 'hsla(0, 0%, 0%, 0.05)',
  neutral: '#DBD4E2',
  strong: '#907BA6',
  strongHover: '#71558C',
  chartGreen: '#70A037',
  chartRed: '#AB5354',
  chartYellow: '#B29015',
  chartNeutral: '#D3D2D1',
  chartNeutralStrong: '#907BA6',
};

export const iconColors = {
  weak: '#B8AAC5',
  neutral: '#907BA6',
  strong: '#4D2A6F',
  error: '#AB5354',
};

export const surfaceColors = {
  raised1: '#4D2A6F',
  raised1Opacity: 'rgba(77, 42, 111, 0.05)',
  raised3: '#E4DFE9',
  raised4: '#907BA6',
  default: '#FFFFFF',
};

import React from 'react';
import { featureFlagsContext } from '../provider/FeatureFlagsProvider';

export enum UserFeatureFlag {
  LIMITED_EXPERIENCE = 'tenant-online-purchasing-limited-experience',
  ANONYMOUS_SURVEY_PUBLISHING = 'tenant-online-purchasing-anonymous-survey-publishing',
}

export const useFeatureFlag = (featureFlag: UserFeatureFlag): boolean => {
  const { featureFlags } = React.useContext(featureFlagsContext);

  const keys = featureFlags.map((flag) => flag.key);

  return keys.includes(featureFlag);
};

export const useGetFeatureFlags = (): UserFeatureFlag[] => {
  const { featureFlags } = React.useContext(featureFlagsContext);

  const keys = featureFlags.map((flag) => flag.key);
  return keys as UserFeatureFlag[];
};

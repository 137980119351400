import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ServiceType } from '../generated';

export const PreviousStepMessage = () => (
  <FormattedMessage
    defaultMessage={'Previous step'}
    description={'Previous step button ie. in surveys'}
  />
);

export const NextStepMessage = () => (
  <FormattedMessage
    defaultMessage={'Next'}
    description={'Next step button ie. in surveys'}
  />
);

export const SaveIncompleteMessage = () => (
  <FormattedMessage
    defaultMessage={'Save incomplete'}
    description={'Save the form in incomplete state.'}
  />
);

export const UpdateAnswersMessage = () => (
  <FormattedMessage
    defaultMessage={'Update answers'}
    description={
      'Update answers for a survey that has been already saved once.'
    }
  />
);

export const CloseMessage = () => (
  <FormattedMessage
    defaultMessage={'Close'}
    description={'Generic close message, closes something like dialogs.'}
  />
);

export const CancelMessage = () => (
  <FormattedMessage
    defaultMessage={'Cancel'}
    description={'Generic cancel message, cancels an action.'}
  />
);

export const LoadingMessage = () => (
  <FormattedMessage
    defaultMessage={'Loading'}
    description={'Generic loading message, toast is loading'}
  />
);

export const ShowMessage = () => (
  <FormattedMessage
    defaultMessage={'Show'}
    description={'Generic show message, shows something.'}
  />
);

export const HideMessage = () => (
  <FormattedMessage
    defaultMessage={'Hide'}
    description={'Generic hide message, hides something.'}
  />
);

export const ServiceNameMessageByServiceType = {
  [ServiceType.External]: (
    <FormattedMessage
      defaultMessage="External service"
      description={'Service type name for external service'}
    />
  ),
  [ServiceType.Informational]: (
    <FormattedMessage
      defaultMessage="Info page"
      description={'Service type name for info page'}
    />
  ),
  [ServiceType.Provided]: (
    <FormattedMessage
      defaultMessage="Service by a provider"
      description={'Service type name for provided service'}
    />
  ),
};

export const SelectMessage = () => (
  <FormattedMessage
    defaultMessage="Select"
    description="Placeholder value for select inputs"
  />
);

export const NoSelectionMessage = () => (
  <FormattedMessage
    defaultMessage="No selection"
    description="Placeholder value for multi-select inputs"
  />
);

export const SelectNoOptionsMessage = () => (
  <FormattedMessage
    defaultMessage={'No options'}
    description={'Dropdown - no options'}
  />
);

export const SaveMessage = () => (
  <FormattedMessage
    defaultMessage={'Save'}
    description={'Saves changes made in the view'}
  />
);

export const ResetChangesMessage = () => (
  <FormattedMessage
    defaultMessage={'Reset changes'}
    description={'Resets changes in the view'}
  />
);

export const SendMessage = () => (
  <FormattedMessage
    defaultMessage={'Send'}
    description={'General send form message, send a form'}
  />
);

export const DoneMessage = () => (
  <FormattedMessage
    defaultMessage={'Done'}
    description={'General done message, completes an action'}
  />
);

export const DeleteMessage = () => (
  <FormattedMessage
    defaultMessage={'Delete'}
    description={'General delete message, deletes something'}
  />
);

export const LanguageByLanguageCode: Record<string, ReactNode> = {
  en: <FormattedMessage defaultMessage="English" />,
  sv: <FormattedMessage defaultMessage="Swedish" />,
  fi: <FormattedMessage defaultMessage="Finnish" />,
  fr: <FormattedMessage defaultMessage="French" />,
  nl: <FormattedMessage defaultMessage="Dutch" />,
  de: <FormattedMessage defaultMessage="German" />,
  ko: <FormattedMessage defaultMessage="Korean" />,
  es: <FormattedMessage defaultMessage="Spanish" />,
};

export const PersonListFilterCountMessage = ({ count }: { count: number }) => (
  <FormattedMessage
    defaultMessage={'{count, plural, one {# person} other {# persons}}'}
    description={'Person listing heading - number of people matching filters'}
    values={{
      count: count,
    }}
  />
);

export const PaginatedListShowMoreMessage = () => (
  <FormattedMessage
    defaultMessage={'Show more'}
    description={'Paginated listing - Show more button label'}
  />
);

export const EditMessage = () => (
  <FormattedMessage
    defaultMessage="Edit"
    description={'General edit message'}
  />
);

export const BackMessage = () => (
  <FormattedMessage
    defaultMessage="Back"
    description="General back message, return action"
  />
);

export const AddMessage = () => (
  <FormattedMessage
    defaultMessage="Add"
    description="General add message, add action"
  />
);

export const GoBackMessage = () => (
  <FormattedMessage defaultMessage="Go back" />
);

export const FieldRequiredErrorMessage = () => (
  <FormattedMessage
    defaultMessage="Please fill out this field"
    description="Form field required error message"
  />
);

export const OkMessage = () => (
  <FormattedMessage defaultMessage="Ok" description="General Ok message" />
);

export const SomethingWentWrong = () => (
  <FormattedMessage
    defaultMessage="Something went wrong"
    description="General toast message for unexpected errors"
  />
);

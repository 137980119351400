import { forwardRef, InputHTMLAttributes, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { brandColors } from '../constants';
import { body1Styles } from '../typography';

const StyledInput = styled.input<{
  isBlank: boolean;
  hasErrors?: boolean;
}>`
  height: 48px;
  padding: 0 ${(props) => (props.hasErrors ? 16 : 17)}px;

  background: white;
  outline: none;

  border: ${(props) =>
    props.hasErrors
      ? css`2px solid ${brandColors.systemError}`
      : css`1px solid ${brandColors.laasPurple60}`};

  box-sizing: border-box;
  border-radius: 2px;

  ${body1Styles};
  font-weight: ${(props) => (props.isBlank ? 'normal' : '600')};

  color: ${brandColors.laasPurple};
  caret-color: black;

  &:focus {
    border: 2px solid ${brandColors.laasPurple};
    padding: 0 16px;
  }

  &:disabled {
    border: 1px solid ${brandColors.coalGrey20};
  }

  ::placeholder {
    color: ${brandColors.coalGrey50};
  }
`;

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  hasErrors?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const [value, setValue] = useState(props.defaultValue || props.value);

    return (
      <StyledInput
        ref={ref}
        type="text"
        isBlank={!value}
        {...props}
        onChange={(event) => {
          setValue(event.target.value);
          props.onChange?.(event);
        }}
      />
    );
  },
);
TextField.displayName = 'TextField';

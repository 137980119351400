import { css } from '@emotion/react';
import { brandColors, mediaQuery } from '../constants';
import { borderColors } from '../colors';

export const defaultCardStyles = css`
  background: ${brandColors.white};
  border: 1px solid ${borderColors.neutral};
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.05);
`;

export const defaultCardContentPadding = css`
  padding: 24px;
  @media screen and ${mediaQuery.medium} {
    padding: 16px;
  }
`;

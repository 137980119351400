import React from 'react';
import { UserFrontendFeatureFlag } from '../generated';

export const featureFlagsContext = React.createContext<{
  featureFlags: UserFrontendFeatureFlag[];
}>({
  featureFlags: [],
});

export const FeatureFlagsProvider = ({
  featureFlags,
  children,
}: React.PropsWithChildren<{
  featureFlags: UserFrontendFeatureFlag[] | undefined;
}>) => {
  return (
    <featureFlagsContext.Provider
      value={{
        featureFlags: featureFlags || [],
      }}
    >
      {children}
    </featureFlagsContext.Provider>
  );
};

import { AppModule, CurrentUserDataFragment } from '../generated';
import _ from 'lodash';
import { hasAdmin } from './userRoleUtils';

const commonSurveyPages = ['/admin/services/themes'];

const protectedPagesByModule: {
  [module in AppModule]?: string[];
} = {
  [AppModule.DevelopmentPlan]: ['/development-plan', '/admin/development-plan'],

  [AppModule.Services]: [
    '/services',
    '/account/order-history',
    '/admin/services',
    '/admin/services/themes',
    '/provider',
    '/admin/system/feedback',
  ],

  [AppModule.Competence]: [
    ...commonSurveyPages,
    '/surveys/competence',
    '/admin/surveys/competence',
    '/admin/questions/competence',
  ],

  [AppModule.Pulse]: [
    ...commonSurveyPages,
    '/surveys/pulse',
    '/admin/surveys/pulse',
    '/admin/questions/pulse',
  ],

  [AppModule.ChangePulse]: [
    ...commonSurveyPages,
    '/surveys/change-pulse',
    '/admin/surveys/change-pulse',
  ],

  [AppModule.EmployeeExperience]: [
    ...commonSurveyPages,
    '/surveys/employee-experience',
    '/admin/surveys/employee-experience',
    '/admin/questions/pulse',
  ],

  [AppModule.WellBeing]: [
    ...commonSurveyPages,
    '/surveys/wellbeing',
    '/admin/surveys/wellbeing',
  ],
};

enum Feature {
  License,
}

const protectedPagesByFeature: {
  [feature in Feature]: string[];
} = {
  [Feature.License]: ['/admin/system/license'],
};

const getProtectedPagesByAppModules = (enabledModules: AppModule[]) =>
  enabledModules.reduce((urls, m) => {
    const pages = protectedPagesByModule[m] || [];
    for (const url of pages) {
      urls.add(url);
    }
    return urls;
  }, new Set<string>());

const getDisallowedUrlMask = (
  enabledModules: AppModule[],
  isLicenseEnabled: boolean,
): string[] => {
  const baseDisallowedMask: string[] = [];

  if (!isLicenseEnabled) {
    baseDisallowedMask.push(...protectedPagesByFeature[Feature.License]);
  }

  if (enabledModules.includes(AppModule.All)) {
    return baseDisallowedMask;
  }

  const allModulePagesMask = getProtectedPagesByAppModules(
    Object.values(AppModule),
  );
  const enabledModulePagesMask = getProtectedPagesByAppModules(enabledModules);

  const disallowedModulePagesMask = [...allModulePagesMask].filter(
    (url) => !enabledModulePagesMask.has(url),
  );

  return [...baseDisallowedMask, ...disallowedModulePagesMask];
};

const getEnabledModules = (
  user: CurrentUserDataFragment | undefined,
  adminModules: AppModule[],
  userModules: AppModule[],
): AppModule[] => {
  if (!user) {
    return [];
  }
  if (hasAdmin(user.userRoles!)) {
    return adminModules;
  }
  return userModules;
};

export class EnabledFeaturesMatcher {
  private readonly enabledModules: AppModule[];
  private readonly disallowedUrlMask: string[];

  constructor(
    user: CurrentUserDataFragment | undefined,
    adminModules: AppModule[],
    userModules: AppModule[],
    licenseEnabled: boolean,
  ) {
    this.enabledModules = getEnabledModules(user, adminModules, userModules);
    this.disallowedUrlMask = getDisallowedUrlMask(
      this.enabledModules,
      licenseEnabled,
    );
  }

  isModuleEnabled(module: AppModule) {
    return (
      this.enabledModules.includes(module) ||
      this.enabledModules.includes(AppModule.All)
    );
  }

  isUrlAllowed(url: string) {
    return _.every(
      this.disallowedUrlMask,
      (mask: string) => !url.startsWith(mask),
    );
  }
}
